const config = require('../static/json/promo.json');
const getDisplayPrice = (price) => {
  const p = Number(price)
  return Math.round(p)
}
export default {
  methods: {
    getPrice (tour, currency) {
      if (!currency) {
        currency = this.$store.state.Common.currency
      }
      const priceMap = tour.priceMap
      if (!priceMap || !priceMap.anchorPrice) {
        return '$0'
      }
      const currencyData = priceMap.currencies.find(c => c.id === currency)
      const roundUpPrice = getDisplayPrice(priceMap.anchorPrice * currencyData.rate)
      if (currencyData) {
        switch (currency) {
          case 'USD':
            return '$' + roundUpPrice
          case 'EUR':
            return '€' + roundUpPrice
          case 'GBP':
            return '£' + roundUpPrice
          case 'CAD':
            return '$' + roundUpPrice
          case 'AUD':
            return '$' + roundUpPrice
          default: 
            return '$' + roundUpPrice
        }
      }
    },
    getPrefixSymbol () {
      const curCurrency = this.$store.state.Common.currency
      if (curCurrency === 'AUD') {
        return 'AU'
      } else if (curCurrency === 'CAD') {
        return 'CA'
      }
      return null
    },
    getAnchorPriceNum (tour, currency = 'USD') {
      const priceMap = tour.priceMap
      if (!priceMap || !priceMap.anchorPrice) {
        return 0
      }
      const currencyData = priceMap.currencies.find(c => c.id === currency)
      const price = Number(priceMap.anchorPrice * currencyData.rate).toFixed(2)
      return price
    },
    getPriceOld (value) {
      /** value.price.data -> listing page,  value.price -> detail page*/
      const data = value.price.data ? value.price.data : value.price;
      switch (this.$store.state.Common.currency) {
        case 'USD':
          return '$' + data.adults_price.USD
        case 'EUR':
          return '€' + data.adults_price.EUR
        case 'GBP':
          return '£' + data.adults_price.GBP
        case 'CAD':
          return '$' + data.adults_price.CAD
        case 'AUD':
          return '$' + data.adults_price.AUD
        default: 
          return '$' + data.adults_price.USD
      }
    },
    /**
    * WLK-3270-promo
    * @param {Array} promos - Array containing all current promoted tours *
    * @param {Int} eventId - ID of event in questions which is checked against promos var *
    */
    checkForPromo (promos, eventId) {
      if (promos.includes(eventId)) {
        return true
      } else {
        if (promos[0] === 'all') {
          return true
        } else {
          return false
        }
      }
    },
    /**
    * WLK-3270-promo
    * @param {Int} price - Price of tour *
    */
    getPromoDiscountedPrice (price) {
      switch (this.$store.state.Common.currency) {
        case 'USD':
          return '$' + (price.USD - (price.USD * config.discount / 100)).toFixed(2)
        case 'EUR':
          return '€' + (price.EUR - (price.EUR * config.discount / 100)).toFixed(2)
        case 'GBP':
          return '£' + (price.GBP - (price.GBP * config.discount / 100)).toFixed(2)
        case 'CAD':
          return '$' + (price.CAD - (price.CAD * config.discount / 100)).toFixed(2)
        case 'AUD':
          return '$' + (price.AUD - (price.AUD * config.discount / 100)).toFixed(2)
      }
    },
    /**
    * WLK-3270-promo
    * @param {Int} price - Price of tour *
    */
    getPromoDiscountedPriceSanitised (price) {
      switch (this.$store.state.Common.currency) {
        case 'USD':
          return (price.USD - (price.USD * config.discount / 100)).toFixed(2)
        case 'EUR':
          return (price.EUR - (price.EUR * config.discount / 100)).toFixed(2)
        case 'GBP':
          return (price.GBP - (price.GBP * config.discount / 100)).toFixed(2)
        case 'CAD':
          return (price.CAD - (price.CAD * config.discount / 100)).toFixed(2)
        case 'AUD':
          return (price.AUD - (price.AUD * config.discount / 100)).toFixed(2)
      }
    },
    /**
    * WLK-3270-promo
    * @param {Array} promos - Array containing all current promoted tours *
    * @param {Object} cart - cart object from payment page. We'll loop through this and try to match event IDs *
    */
    checkCartItemsForPromo (promo, cart) {
      var promoBoolean = false
      console.log('called', promo, cart);
      cart.forEach(cart => {
        if (promo.includes(cart.eventID)) {
          console.log('1')
          promoBoolean = true
        } else {
          console.log('else')
          if (promo[0] === 'all' && !config.excludedEvents.includes(cart.eventID)) {
            console.log('2')
            promoBoolean = true
          } else {
            console.log('3')
            promoBoolean = false
          }
        }
      })
      return promoBoolean
    },
    /**
    * WLK-3270-promo
    * @param {String} date - current selected date *
    * @param {Object} dateInfo - Obj containg month, start & end date *
    */
    checkPromoStatus (date, dateInfo, dateSelected) {
      // date = date.toString()
      let check = true
      // dateInfo.forEach((dateInfo, i) => {
      //   if (date.includes(dateInfo.month) && date.includes(dateInfo.year) && ((new Date(dateSelected) >= new Date(dateInfo.start)) === true) && ((new Date(dateSelected) <= new Date(dateInfo.end)) === true)) {
      //     check = true
      //   }
      // })
      return check
    }
  }
}
