
import Home from './Home'
import Common from './Common'
import TourList from './TourList'
import StaticPages from './StaticPages'
import Review from './Review'
import Vuex from 'vuex'

let store

const createStore = () => {
  return store || (store = new Vuex.Store({
    namespaced: true,
    modules: {
      Home,
      Common,
      TourList,
      StaticPages,
      Review
    }
  }));
};

export default createStore